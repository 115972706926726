import {ohNo} from '@/modules/oh_no';

export default function(element?: HTMLElement) {
  if (!element) return;
  const rect = element.getBoundingClientRect();
  const elementTop = rect.top;
  const elementBottom = rect.bottom;
  if (!window.visualViewport) {
    ohNo(`window.visualViewport is missing (isInViewport)`);
    return true;
  }

  const viewportTop = window.visualViewport.pageTop;
  const viewportBottom = window.visualViewport.pageTop + window.visualViewport.height;

  return elementBottom > viewportTop && elementTop < viewportBottom;
}
